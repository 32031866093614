/** @format */

import React, { useState } from 'react';
import ProjectBar from '../components/ProjectBar';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import HalfPhoto from '../components/HalfPhoto';

import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
// import Test from '../components/test.md';

import penskecover from '../images/penskecoverlarge.png';
import penskecover2 from '../images/penskecover.png';

import infoarch from '../images/infoarch.jpg';
import vf1 from '../images/VF1.png';
import vf2 from '../images/VF2.png';
import vf3 from '../images/VF3.png';
import vf4 from '../images/VF4.png';
import vf5 from '../images/subsubs.png';

import { navigate } from 'gatsby-link';

import lu from '../images/lucover2.png';
import mb from '../images/mbcoverlarge.png';
import otherwork from '../images/otherwork.jpg';

const CyberCMS = () => {
    return (
        <Layout>
            <Title
                title="Penske Truck Rental"
                subtitle="Improving Vehicle Notification Subscriptions"
                categories="UX/UI Design, Front-End Development, User Research"
            />
            <Photo
                link={penskecover2}
                subtitle="Single Vehicle Search Results"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Overview</h2>
                        <h3 className="first">Roles</h3>
                        <p className="firstp">Product Design, User Research</p>
                        <h3>Client</h3>
                        <p className="firstp">Penske Truck Rentals</p>
                        <h3>Problem</h3>
                        <p className="firstp">
                            The existing subscription feature overwhelmed users
                            with excessive location update emails, leading them
                            to abandon it in favor of time-consuming manual
                            vehicle tracking.
                        </p>
                        <h3>Business Goal</h3>
                        <p className="firstp">
                            Improve the subscriptions feature to increase
                            workflow efficiency and to help the Recovery Team
                            users recover vehicles faster.
                        </p>
                        <h3>Solution</h3>
                        <p className="firstp">
                            An email notification system that only sends users
                            emails when vehicles reach specific actionable
                            criteria, overhaul the Subscribed Vehicles list to
                            show relevant information, and reformat the email
                            notifications to a more readable and informative
                            format.
                        </p>
                        <h3>Results</h3>
                        <p className="firstp">
                            Users are now subscribing to around 30 units each,
                            and over an hour of manual research work has been
                            cut out daily for each user.
                        </p>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <SmallPhoto link={vf1} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Background</h2>
                        <h3 className="first">Why track stolen vehicles?</h3>
                        <p className="firstp">
                            Penske faces a growing challenge of rising vehicle
                            theft. Over the last few years, the number of thefts
                            has been increasing by about 5% a year. Ensuring
                            Penske’s vehicle security team is able to properly
                            notify authorities of the location of these stolen
                            vehicles is a priority for them.
                        </p>
                        <p>
                            The screenshots shown on this page are fully
                            designed by me.
                        </p>
                        <h3>Identifying the Issue</h3>
                        <p className="firstp">
                            When a subscribed vehicle sends a location ping, an
                            email is triggered to notify the user. In cases
                            where multiple vehicles send pings simultaneously,
                            users receive numerous emails at once. To address
                            this, I was tasked with introducing a toggle that
                            allows users to consolidate these notifications into
                            a single email when multiple pings occur.
                        </p>

                        <p>
                            Before starting the work, I reached out to the
                            Security team to understand how subscriptions were
                            being used. I learned that the feature was
                            completely unused because the overwhelming volume of
                            alerts rendered it impractical. Zero users were
                            using the feature as a result.
                        </p>

                        <h3>Problem</h3>
                        <p className="firstp">
                            Each team member spends upwards of an hour each day
                            manually verifying the location of around 30 stolen
                            vehicles, waiting for them to meet specific
                            criteria. Since the team members aren’t checking
                            constantly this leads to missed vehicle recovery
                            opportunities and overall slower recovery times.
                        </p>
                        <h3>Business Goal</h3>
                        <p className="firstp">
                            Money is lost each day a vehicle is not rented
                            legitimately. Getting a stolen vehicle back into the
                            rentable fleet faster would ensure they are
                            available for legitimate customers and maximize
                            revenue for Penske.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={vf2} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Solution</h2>
                        <h3 className="first">
                            Overhaul the exisiting vehicle subscription feature
                        </h3>
                        <p className="firstp">
                            Improve the subscriptions feature to increase
                            workflow efficiency and help the Recovery Team users
                            recover vehicles faster by creating{' '}
                            <b>informative</b> and <b>actionable</b>{' '}
                            notifications
                        </p>

                        <h3>Creating Relevant Notifications</h3>
                        <p className="firstp">
                            I worked with the security team to learn what
                            criteria they look for before alerting authorities
                            and determined 3 notification types were needed.
                        </p>
                        <p style={{ margin: 0 }}>
                            <ul
                                className="subul"
                                style={{
                                    listStyleType: 'none',
                                }}>
                                <li style={{}}>
                                    <b
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                        }}>
                                        Stopped Alert:
                                    </b>{' '}
                                    Notification when a vehicle is stopped for a
                                    user-defined duration.
                                </li>
                                <li style={{ marginTop: '8px' }}>
                                    <b
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                        }}>
                                        Movement Alert:
                                    </b>{' '}
                                    Notification when a vehicle moves after
                                    being stationary for 15 minutes.
                                </li>
                                <li style={{ marginTop: '8px' }}>
                                    {' '}
                                    <b
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                        }}>
                                        Functioning Tracker Alert:
                                    </b>{' '}
                                    Notification when a tracker is able to
                                    reestablish its connection with Vehicle
                                    Finder.
                                </li>
                            </ul>
                        </p>
                        {/* <p className="subh first">Stopped Alerts</p>
                        <p className="subp firstp">
                            Notification when a vehicle is stopped for a
                            user-defined duration.
                        </p>
                        <p className="subh">Movement Alerts</p>
                        <p className="subp firstp">
                            Notification when a vehicle moves after being
                            stationary for 15 minutes.
                        </p>
                        <p className="subh">Functioning Tracker Alerts</p>
                        <p className="subp firstp">
                            Notification when a tracker pings for the first time
                            after a week without activity.
                        </p> */}
                    </div>
                </div>
            </div>
            <SmallPhoto link={vf3} subtitle="" />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Improving Legiblity of Email Notifications</h3>
                        <p className="firstp">
                            The original notifications were essentially JSON
                            converted to text form. I created a more readable
                            format.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={vf4} subtitle="" />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Adding a dashboard for at a glance information</h3>
                        <p className="firstp">
                            Although the new notifications eliminated the need
                            for team members to manually check vehicle statuses,
                            they still wanted an easy way to view the current
                            state of each vehicle. To address this, I updated
                            the Subscribed Vehicles List page to display key
                            status information, including whether a vehicle is
                            stopped or in motion, the duration of its stop, and
                            whether the tracker is actively sending data.
                        </p>
                        <p>
                            A small subset of users occasionally used the
                            existing subscription method that would send every
                            vehicle update in order to share information with
                            the authorities in real time, so I included a
                            “Stream” option to turn it on for specific vehicles.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={vf5} subtitle="" />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h3 className="first">Results</h3>
                        <p className="firstp">
                            <b>
                                Users are now subscribing to around 30 units
                                each, and over an hour of work has been cut out
                                daily for each user.
                            </b>{' '}
                            Users no longer need to manually look into each
                            vehicle they are tracking. They can subscribe and
                            wait to take action once the appropriate email
                            notification has been received.
                        </p>
                        <p>
                            <b>
                                Vehicle recovery time has also decreased by 8%,
                                vehicles are recovered about a day faster on
                                average.
                            </b>
                        </p>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2></h2>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/penske/`)}>
                            <img
                                className="project-photo"
                                src={penskecover}></img>
                            <div className="project-name">Penske</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img
                                className="project-photo"
                                src={otherwork}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default CyberCMS;
